.member-container {
  display: flex;
  width: 95%;
  justify-content: center;
  justify-items: center;
}
.member-header {
  width: 20%;
  min-width: 180px;
}
.member-middle {
  width: 5%;
}
.member-body {
  width: 20%;
}
.member-cell {
  margin: 15px 0;
}
.editButton {
  display: flex;
  width: 95%;
  justify-content: center;
}
.noRecorddiv {
  color: grey;
}
.gamelistDiv {
  width: 25%;
  background-color: var(--gameList_background);
}
.gamelistDiv1 {
  background-color: var(--gameList_background);
}
@media (max-width: 768px) {
  .gamelistDiv {
    width: 33.33%;
    min-width: 140px;
  }
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Adjust the number of lines as needed */
}
.card-button-border {
  border: 1px solid grey;
  padding: 6px;
  border-radius: 5px;
  color: grey;
}
.tansferDialogCard {
  background-color: var(--gameList_background);
  padding: 1vh 1vh;
}
.inputBox {
  height: 38px;
  text-align: center;
  background-color: var(--table-background);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: var(--body_color);
  border-radius: 5px;
}
.date-picker-container {
  margin-bottom: 15px;
  background-color: var(--table-background);
}

.date-picker-container label {
  display: block;
  margin-bottom: 5px;
}
