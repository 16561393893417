:root {
  --body_background: rgba(219, 219, 219, 1);
  --body_color: black;
  --body_textBox: white;
  /* --link_color: navy; */
  --box-shadow_color: rgba(1, 41, 112, 0.1);
  --table-background: white;
  --table-background1: white;
  --placeholder-color: rgba(0, 0, 0, 0.5);
  --gameList_background: white;
}

[data-theme="dark"] {
  --body_background: #282c34;
  --body_color: white;
  --body_textBox: #1c1e23;
  --box-shadow_color: rgba(247, 247, 247, 0.1);
  --table-background: rgb(33, 35, 41);
  --table-background1: rgba(33, 35, 41);
  --placeholder-color: rgba(255, 255, 255, 0.5);
  --gameList_background: rgba(24, 7, 7, 0.2);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
