/* Import Roboto font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

/* Import Nunito font */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

:root {
  scroll-behavior: smooth;
  --primary: #4154f1;
  --second: #717ff5;
  --bgColor: #f6f9ff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: "Open Sans", sans-serif; */
  color: #444444;
  background-color: var(--body_background);
  color: var(--body_color);
}

a {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--second);
  text-decoration: none;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
} */

.text-color {
  color: var(--body_color);
}
.inputStyle {
  width: 90%;
  height: 35px;
  border-radius: 5px;
  padding-left: 2%;
  padding-right: 2%;
  box-sizing: border-box;
  color: var(--body_color);
  background: var(--body_textBox);
}
.inputStyle:focus {
  background: var(--body_textBox);
  color: var(--body_color);
}
.inputStyle::placeholder {
  color: var(--placeholder-color);
}
.inputStyle1 {
  background: var(--body_textBox);
  color: var(--body_color);
}
.custom-swal {
  background-color: var(--body_background);
  color: var(--body_color);
}
