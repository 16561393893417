.agent-container {
  width: 100%;
  position: relative;
  background-color: var(--table-background);
  border-radius: 5px;
}
.agent-header {
  width: 96%;
  margin: auto;
  border-bottom: 1px solid rgb(175, 164, 164, 0.4);
}

.agent-body {
  width: 96%;
  margin: auto;
}
.agent-cell {
  text-align: start;
  padding: 5px;
  align-content: center;
  height: 48px;
}

.agent-row {
  display: flex;
  border-bottom: 1px solid rgb(175, 164, 164, 0.2);
}

.no-record-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.oRecordDiv {
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agentPermission {
  width: 85%;
  margin: 1vh auto;
  align-items: center;
  align-content: center;
  display: flex;
}
.agentPermission span {
  align-items: center;
}
.agentDetails-container {
  display: flex;
  width: 90%;
  justify-content: center;
  justify-items: center;
  margin: "5px auto";
}
.agentDetails-header {
  width: 20%;
  min-width: 180px;
}
.agentDetails-middle {
  width: 5%;
}
.agentDetails-body {
  width: 20%;
}
.agentDetails-cell {
  margin: 15px 0;
}
.editButton {
  display: flex;
  width: 95%;
  justify-content: center;
}
.buttonCss {
  margin: 20px auto;
  align-items: center;
}
.buttonName {
  background-color: transparent;
  border: none;
  color: #0080ff;
  text-decoration: underline;
}
.breadcrumb {
  display: flex;
  align-items: center;
}

.breadcrumb-agent {
  display: flex;
  align-items: center;
}

.breadcrumb-separator {
  margin: 0 5px;
}

.breadcrumb-item {
  display: inline-block;
}
.agent-table {
  width: 100%;
  border-collapse: collapse;
}
.agent-header .fixed-column {
  z-index: 3;
}

.fixed-column {
  position: sticky;
  left: 0;
  background-color: var(--table-background1);
  z-index: 2;
}
.search-container {
  width: 100%;
  background-color: var(--table-background);
  border-radius: 5px;
}
.search-header {
  width: 96%;
  margin: auto;
  display: flex;
  border-bottom: 1px solid rgb(175, 164, 164, 0.4);
}

.search-body {
  width: 96%;
  margin: auto;
}
.search-cell {
  text-align: start;
  padding: 5px;
  align-content: center;
}
.search-row {
  display: flex;
  border-bottom: 1px solid rgb(175, 164, 164, 0.2);
}

@media (max-width: 768px) {
  .agentDetails-body {
    width: 50%;
  }
  .agentDetails-header {
    width: 50%;
  }
  .hide-on-mobile {
    display: none;
  }
  .agent-table {
    min-width: 500px;
  }
}
