.member-container {
  width: 100%;
  position: relative;
  background-color: var(--table-background);
  border-radius: 5px;
}

.member-header1 {
  width: 96%;
  margin: auto;
  border-bottom: 1px solid rgba(175, 164, 164, 0.4);
}

.member-body {
  width: 96%;
  margin: auto;
}

.member-cell {
  text-align: center;
  padding: 5px;
  align-content: center;
  height: 48px;
}

.member-row {
  display: flex;
  border-bottom: 1px solid rgba(175, 164, 164, 0.2);
}
.member-row1 {
  border-bottom: 1px solid rgba(175, 164, 164, 0.2);
}
.member-table {
  width: 100%;
  border-collapse: collapse;
}

.table-responsive {
  width: 100%;
}
.no-record-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px; /* Adjust based on your needs */
}

.oRecordDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

  .member-table {
    min-width: 500px;
  }

  .member-container {
    width: 100%;
    position: relative;
    background-color: var(--table-background);
    border-radius: 5px;
    overflow-x: auto;
  }
  .table-responsive {
    overflow-x: auto;
    width: 100%;
  }
}
