/* Change the text color of the date range */
.rdrDayInRange span,
.rdrDayInRange:hover span,
.rdrDayStartPreview span,
.rdrDayStartPreview:hover span,
.rdrDayEndPreview span,
.rdrDayEndPreview:hover span {
  color: var(--body_color); /* Your desired text color */
}

.rdrCalendarWrapper,
.rdrDateRangeWrapper,
.rdrDateDisplayWrapper,
.rdrDateDisplay,
.rdrDayDisabled,
.rdrDateInput,
.rdrDateDisplayItem,
.rdrDateDisplayItemActive .calendarElement {
  background-color: var(--table-background);
}

.rdrMonthPicker select,
.rdrYearPicker select {
  border: 1px solid var(--box-shadow_color);
  border-radius: 5px;
  text-align: center;
  background-color: var(--table-background);
  overflow-y: auto;
  color: var(--body_color);
}
.rdrMonthPicker select:hover,
.rdrYearPicker select:hover {
  border: 1px solid #0080ff;
  border-radius: 5px;
  text-align: center;
  background-color: var(--table-background);
  overflow-y: auto;
  color: var(--body_color);
}
.rdrMonth .rdrDays,
.rdrDayNumber span,
.rdrDateDisplay span input {
  color: var(--body_color);
}

@media (max-width: 768px) {
  .calendarElement {
    width: 98%;
  }
}
